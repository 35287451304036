
.h1 {
  @apply text-headerL text-center
  md:text-headerXL lg:text-header2XL
}

.h2 {
  @apply text-headerM
  md:text-headerL lg:text-header2XL
}
.h21 {
  @apply text-headerL text-center
  md:text-headerXL lg:text-header2XL
}

.h3 {
  @apply text-headerM text-primary
  md:text-headerL lg:text-header2XL
}

.body {
  @apply text-bodyS1
  md:text-bodyM1 lg:text-bodyL1
}

/* Add padding as amount of header height (S: 48px, ML: 64px) */
/* Add additional padding (as margin) S:24, M:32, L:40 */
/* Thus total padding top is S:72, M:96, L:104 */
.mainContainer {
  @apply w-full pt-[72px] flex flex-col bg-background
  md:pt-[96px] lg:pt-[104px]
}

.mainWrap {
  @apply w-[312px] my-0 mx-auto md:w-[592px] lg:w-[1064px]
}

.hideDetailsMarker::-webkit-details-marker {
  display: none;
}